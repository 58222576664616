import React from 'react';
import '../../components/footer/FooterStyles.css';
import { GoMail } from 'react-icons/go';
import { BsInstagram } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <div className='footer__container'>
        <div className="text__contact">
          <h4>Contáctanos</h4>
          <h5><a href="mailto:info@romanaebanisteria.com">chavconnect@lromanarealestate.com</a></h5>
          <h6><a href="tel:+18292222484">+1 (829)222-2484</a></h6>
        </div>

        <div className="social__media">
          <div className="social__icons">
            <a href="mailto:chavconnect@lromanarealestate.com">
              <GoMail className="icon" size={35} color="white" />
            </a>
            <a href="https://www.instagram.com/lromanarealestate/" target="_blank" rel="noopener noreferrer">
              <BsInstagram className="icon" size={35} color="white" />
            </a>
            <a href="tel:+829-222-2484">
              <FiPhoneCall className="icon" size={35} color="white" />
            </a>
            <a href="https://wa.link/xqzqlr">
              <FaWhatsapp className="icon" size={35} color="white" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
