import React, { useState } from 'react';
import '../../components/MainTop/MainTopStyles.css';
import Logo from '../assets/relogo.png';
import Footer from '../footer/Footer';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { motion, AnimatePresence } from 'framer-motion';
import backgroundVideo from '../assets/videoplayback.mp4'; // Ruta al video en tu proyecto

const MainTop = () => {
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClick = () => {
    setMostrarFormulario(!mostrarFormulario);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: `${nombre} ${apellido}`,
      phone: telefono,
      email: email,
      interest: 'ChavConnect',
    };

    try {
      const response = await fetch(
        'https://chat.falitech.com/api/iwh/80cf6651547fd12b39b06f92f75ff364',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        setIsSubmitted(true);
        setNombre('');
        setApellido('');
        setEmail('');
        setTelefono('');

        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error al enviar el formulario", error);
    }
  };

  return (
    <div className='main__container'>
      {/* Video de fondo */}
      <div className="video-background">
        <video autoPlay muted loop playsInline>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Capa negra difuminada */}
      <div className="overlay"></div>

      <motion.div
        className="romana__logo"
        initial={{ y: 0 }}
        animate={{ y: mostrarFormulario ? -50 : 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src={Logo} alt="logo" className="logo__image" />
      </motion.div>

      <motion.div
        className="text__container"
        initial={{ y: 0 }}
        animate={{ y: mostrarFormulario ? -50 : 0 }}
        transition={{ duration: 0.5 }}
      >
        <p className='text'>un espacio que combina creatividad, comunidad y productividad.</p>
      </motion.div>

      <div className="contact__container">
        <div className="contact__form">
          <p onClick={handleClick}>
            Solicita tu Oficina<AiOutlineArrowDown className='icon' />
          </p>
          <AnimatePresence>
            {mostrarFormulario && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <form onSubmit={handleSubmit}>
                  <label htmlFor="nombre">Nombre</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    required
                  />

                  <label htmlFor="apellido">Apellido</label>
                  <input
                    type="text"
                    id="apellido"
                    name="apellido"
                    value={apellido}
                    onChange={(e) => setApellido(e.target.value)}
                    required
                  />

                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <label htmlFor="telefono">Teléfono</label>
                  <input
                    type="tel"
                    id="telefono"
                    name="telefono"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    required
                  />

                  <button type="submit">Enviar</button>

                  {isSubmitted && (
                    <p className="success-message">¡Enviado con éxito!</p>
                  )}
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MainTop;
